import { Invoice } from "./Invoice";
import store from "../store/store";
import { CustomerState } from "../store/reducers/Customers";
import { UuidNil } from "../common/uuid";


export interface Accountable {
    GetCreatedDate(): Date
}

export const FilterFinalInvoices = (invoice: Invoice) => !invoice.draft && !invoice.quote

export const FilterExternalInvoices = (invoice: Invoice) => {
    const state: CustomerState = store.getState().Customers
    const customer = state.getCustomerById(invoice.customer)
    return customer !== null && customer.is_tenant === UuidNil
}

export class AccountableContainers<T extends Accountable> {

    constructor(containers: AccountableContainer<T>[]) {
        this.containers = containers;
    }

    containers: AccountableContainer<T>[];

    public CalcTotal(fn: (accountable: T) => number): number[] {
        return this.containers.map(container => container.CalcTotal(fn))
    }
    public Count(): number[] {
        return this.containers.map(container => container.Count())
    }

    public ForEach(fn: (accountable: T) => void): AccountableContainers<T> {
        this.containers.map(container => {
            container.ForEach(fn)
            return true
        })
        return this
    }

    public GetMonths(): string[] {
        const label = (date?: Date): string => {
            if (date === undefined) {
                return ""
            }
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            return monthNames[date.getMonth()] + " '" + (date.getFullYear() - 2000)
        }
        return this.containers.map(container => label(container.month))
    }

    public Add(container: AccountableContainer<T>) {
        this.containers.push(container)
    }

}

export class AccountableContainer<T extends Accountable> {

    month?: Date;
    accountables: T[];

    constructor(accountables: T[]) {
        this.accountables = accountables;
    }

    private getStartOfThisQuarter(): Date {
        var today = new Date()
        var quarter = Math.floor((today.getMonth() / 3))
        return new Date(today.getFullYear(), quarter * 3, 1)
    }
    private getEndOfThisQuarter(): Date {
        return new Date(this.getStartOfThisQuarter().getFullYear(), this.getStartOfThisQuarter().getMonth() + 3, 0, 23, 59, 59)
    }

    private getStartOfPreviousQuarter(): Date {
        var today = new Date()
        var quarter = Math.floor((today.getMonth() / 3))
        return new Date(today.getFullYear(), quarter * 3 - 3, 1)
    }
    private getEndOfPreviousQuarter(): Date {
        return new Date(this.getStartOfPreviousQuarter().getFullYear(), this.getStartOfPreviousQuarter().getMonth() + 3, 0, 23, 59, 59)
    }

    public RangePreviousQuarter(): AccountableContainer<T> {
        return new AccountableContainer<T>(
            this.accountables
                .filter(accountable =>
                    accountable.GetCreatedDate() >= this.getStartOfPreviousQuarter() &&
                    accountable.GetCreatedDate() < this.getEndOfPreviousQuarter()
                )
        )
    }
    public RangeThisQuarter(): AccountableContainer<T> {
        return new AccountableContainer<T>(
            this.accountables
                .filter(accountable =>
                    accountable.GetCreatedDate() >= this.getStartOfThisQuarter() &&
                    accountable.GetCreatedDate() < this.getEndOfThisQuarter()
                )
        )
    }

    // public FilterOnlyFinals(): AccountableContainer<T> {
    //     return new AccountableContainer<T>(
    //         this.accountables
    //             .filter(accountable =>
    //                 !accountable.draft && !accountable.quote
    //             )
    //     )
    // }

    public Filter(fn: (arg: T) => boolean): AccountableContainer<T> {
        return new AccountableContainer<T>(
            this.accountables
                .filter(fn)
        )
    }

    public EveryMonth(limit?: number): AccountableContainers<T> {
        if (limit === undefined) { limit = 12 }

        const containers = new AccountableContainers<T>([])

        for (let i = limit - 1; i >= 0; i--) {
            const monthStart = new Date((new Date()).getFullYear(), (new Date()).getMonth() - i, 1);
            const monthEnd = new Date((new Date()).getFullYear(), ((new Date()).getMonth() - i) + 1, 1);
            const container = new AccountableContainer<T>(this.accountables.filter(accountable => {
                return accountable.GetCreatedDate() >= monthStart && accountable.GetCreatedDate() < monthEnd
            }));
            container.month = monthStart
            containers.Add(container)
        }

        return containers;
    }

    // public CalcRevenue(): number {
    //     let total = 0;
    //     this.accountables.map(accountable => {
    //         total += accountable.CalcTotalContents()
    //         return true
    //     })
    //     return total
    // }

    public CalcTotal(fn: (accountable: T) => number): number {
        let total = 0;
        this.accountables.map(accountable => {
            total += fn(accountable)
            return true
        })
        return total
    }

    public ForEach(fn: (accountable: T) => void): AccountableContainer<T> {
        this.accountables.map(accountable => {
            fn(accountable)
            return true
        })
        return this
    }

    // public CalcGrantTotal(): number {
    //     let total = 0;
    //     this.invoices.map(invoice => {
    //         total += invoice.CalcGrandTotal()
    //         return true
    //     })
    //     return total
    // }

    public Count(): number {
        return this.accountables.length
    }

}