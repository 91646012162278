import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "store/store";
import { Button, Card, CardBody } from "reactstrap";
import Date from "../../components/Date";
import { InvoiceState } from "../../store/reducers/Invoices";
import { getOrphanedPayments, linkOrphanedPayment } from "../../store/actions/Billing";
import { Invoice } from "../../models/Invoice";

interface Props {
    invoiceState: InvoiceState;
    invoice?: Invoice;
}

interface Actions {
    getOrphanedPayments: typeof getOrphanedPayments;
    linkOrphanedPayment: typeof linkOrphanedPayment;
}

const OrphanedPaymentsCard: React.FC<Props & Actions> = ({ invoice, linkOrphanedPayment, getOrphanedPayments, invoiceState }) => {

    useEffect(() => {
        getOrphanedPayments()
        // eslint-disable-next-line
    }, []);

    if (invoiceState.getOrphanedPayments().length === 0) {
        return <></>;
    }

    let payments = invoiceState.getOrphanedPayments()
    if (invoice) {
        payments = payments.filter(payment => Math.abs(payment.amount - invoice.CalcGrandTotal(21)) < 0.02)
    }

    return (
        <Card>
            <CardBody>
                <div>
                    <p className="text-muted text-uppercase fw-semibold">Orphaned payments</p>
                </div>
                {invoiceState.getOrphanedPayments().length === 0 ?
                    <div style={{ textAlign: "center" }}>No calls found</div>
                    :
                    <ul className="message-list" style={{ maxHeight: "150px", overflowY: "auto", marginBottom: "0" }}>
                        {payments
                            .sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0)
                            .slice(0, 10)
                            .map(payment => {
                                return <li key={payment.id} className={`mail-row`}>
                                    <div className="col-mail col-mail-1 no-left">
                                        <b className="title">{payment.metadata["counter_name"]} <small> - {payment.metadata["counter_iban"]}</small></b>
                                    </div>
                                    <div className="col-mail col-mail-2 no-left">
                                        <div className="subject">
                                            <span className="badge bg-primary me-1 font-size-12 me-2 mb-3">
                                                &euro; {payment.amount.toFixed(2)}
                                            </span>
                                            <span className="teaser" style={{ color: "grey" }}>
                                                {payment.metadata["description"]}
                                            </span>
                                        </div>
                                        <div className="date">
                                            <Date date={payment.date} />
                                        </div>
                                        {invoice &&
                                            <Button
                                                type="button"
                                                color="danger"
                                                className="btn btn-danger btn-block waves-effect waves-light fw-semibold"
                                                onClick={() => linkOrphanedPayment(payment.id, invoice?.id)}
                                                style={{ right: 0, position: "absolute", top: '-15px' }}
                                            >
                                                Link
                                            </Button>
                                        }
                                    </div>
                                </li>
                            })}
                    </ul>
                }
            </CardBody>
        </Card>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ getOrphanedPayments, linkOrphanedPayment }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OrphanedPaymentsCard);
