import WaitingDots from 'components/WaitingDots';
import { Customer } from 'models/Customer';
import { CustomerBilling } from 'models/CustomerBilling';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { vatCheck } from 'services/Api';
import { editCustomer } from 'store/actions/Customers';

interface Props {
    customer: Customer;
}

interface Actions {
    editCustomer: (c: Customer) => void;
}

const CustomerVatCheckCard: React.FC<Props & Actions> = ({ customer, editCustomer }) => {

    const [customerState] = useState<Customer>(customer)
    const [editVatState, setVatState] = useState<boolean>(false)
    const [billingState, setBillingState] = useState<CustomerBilling>(customer.billing)
    const [vatInfo, setVatInfo] = useState<any>("info not available")

    const resultBox = useRef<HTMLElement>(null);


    const matchAddress = (data: any) => {

        if (resultBox.current === null) {
            return;
        }

        let prefix = " ";
        for (let i = 0; i < 5 - (customer.address.number.replace(/\D/g, '')).length; i++) {
            prefix += "0";
        }

        const streetNotation = (customer.address.street.toUpperCase() + prefix + customer.address.number.replace(/\D/g, '') + " " + customer.address.number.replaceAll("" + parseInt(customer.address.number), "")).trim()

        const offset = data.address.split("\n").length === 4 ? 1 : 0;
        if (data.address.split("\n")[offset].toUpperCase() !== streetNotation) {
            resultBox.current.style.color = "red";
            return;
        }
        if (data.address.split("\n")[offset+1].split(" ")[0] !== customer.address.zip_code) {
            resultBox.current.style.color = "red";
            return;
        }
        if (data.address.split("\n")[offset +1].split(" ")[1] !== customer.address.city.toUpperCase()) {
            resultBox.current.style.color = "red";
            return;
        }


        resultBox.current.style.color = "green";
    }

    const setVatInfoCorrect = React.useCallback((vatNumber: string) => {
        vatCheck(vatNumber)
            .then((r) => {
                if (r.isValid) {
                    setVatInfo(objToString(r))

                    matchAddress(r)
                } else {
                    setVatInfo("the vat-number is not valid")
                }
            })
            .catch(() => {
                setVatInfo("the vat-number is not valid")
            })
        // eslint-disable-next-line
    }, []);



    useEffect(() => {
        setVatInfoCorrect(billingState.vat_number)
    }, [setVatInfoCorrect, billingState])

    const editVat = () => {
        customerState.billing = billingState;
        editCustomer(customerState)
            //@ts-ignore
            .then(() => {
                setVatState(false)
                setVatInfoCorrect(billingState.vat_number)

            });
    }


    function objToString(obj: any) {
        var str = '';
        for (var p in obj) {

            if (Object.prototype.hasOwnProperty.call(obj, p) && (p === "name" || p === "address")) {
                str += p + ': ' + obj[p] + '\n';
            }
        }
        return str;
    }

    if (customer === undefined) {
        return <WaitingDots />
    }

    return (
        <Card>
            <CardBody>
                <div>
                    <p className="text-muted text-uppercase fw-semibold" style={{ fontSize: "13px" }}>Vat check</p>
                </div>
                <hr />
                <table className="table table-borderless align-middle table-centered table mb-0">
                    <tbody>
                        <tr>
                            <td>Vat number</td>
                            <td>

                                {editVatState ?
                                    <input type="text" defaultValue={customer.billing.vat_number} className="form-control" placeholder="Vat number"

                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                editVat()

                                            }
                                        }}

                                        onChange={e => {
                                            billingState.vat_number = e.target.value;
                                            setBillingState(billingState)
                                        }} />
                                    :
                                    customer.billing.vat_number
                                }
                            </td>

                            {!editVatState &&
                                <td>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setVatState(true)
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                    >
                                        <i className=" bx bx-edit-alt"></i>
                                    </button>
                                </td>
                            }
                        </tr>
                    </tbody>
                </table>
                <hr />
                <code ref={resultBox} style={{ whiteSpace: 'pre-wrap' }}>{vatInfo}</code>
            </CardBody>
        </Card >
    );
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({ editCustomer }, dispatch);
};

export default connect(null, mapDispatchToProps)(CustomerVatCheckCard);
