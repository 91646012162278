import React, {useEffect} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {RootState} from "store/store";
import {Card, CardBody} from "reactstrap";
import {getCalls} from "../../store/actions/Calls";
import {CallsState} from "../../store/reducers/Calls";
import {Link} from "react-router-dom";
import Date from "../../components/Date";
import {PersonsState} from "../../store/reducers/Persons";
import {getPersons} from "../../store/actions/Customers";

interface Props {
    callState: CallsState;
    persons: PersonsState;
}

interface Actions {
    getCalls: () => void;
    getPersons: typeof getPersons;
}

const RecentCallsCard: React.FC<Props & Actions> = ({getPersons, persons, callState, getCalls}) => {

    useEffect(() => {
        getCalls()
        getPersons()
        // eslint-disable-next-line
    }, []);

    const statusMap: { [index: string]: JSX.Element } = {
        "ANSWERED": <i className="fa fa-check" title={"Answered"}/>,
        "ENQUEUED": <i className="fa fa-hourglass" title={"Enqueued"}/>,
        "BLOCKED": <i className="fa fa-ban" title={"Blocked"}/>,
        "REJECTED-OOO": <i className="fa fa-clock" title={"Out of office"}/>,
        "LEFT": <i className="fa fa-arrow-circle-right" title={"Left queue"}/>,
    }

    return (
        <Card>
            <CardBody>
                <div>
                    <p className="text-muted text-uppercase fw-semibold" style={{fontSize: "13px"}}>Recent
                        calls</p>
                </div>
                {callState.getCalls().length === 0 ?
                    <div style={{textAlign: "center"}}>No calls found</div>
                    :
                    <ul className="message-list" style={{maxHeight: "150px", overflowY: "auto", marginBottom: "0"}}>
                        {callState.getCalls()
                            .sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0)
                            .slice(0, 20)
                            .map(call => {
                                let person = persons.getPersonById(call.person)
                                if (person === null) {
                                    person = persons.getPersonByPhone(call.from)
                                }

                                return <li key={call.id} className={`mail-row`} style={{height: "25px"}}>
                                    <div className="col-mail col-mail-2 no-left" style={{top: "0"}}>
                                        <div className="subject">
                                            <span title={call.status}
                                                  className="badge bg-primary me-1 font-size-12 me-2 mb-3">
                                                {statusMap[call.status]}
                                            </span>
                                            <b className="title">{person === null ? <>{call.from}</> : <Link
                                                to={`/customers/${person.getDefaultCustomerId()}`}>{person.name} ({call.from})</Link>}</b>
                                        </div>
                                        <div className="date">
                                            <Date date={call.GetDate()}/>
                                        </div>
                                    </div>
                                </li>;
                            })}
                    </ul>
                }
            </CardBody>
        </Card>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        persons: state.Persons,
        callState: state.Calls,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({getCalls, getPersons}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentCallsCard);
