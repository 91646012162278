import PropTypes from "prop-types";
import React from "react";
import SimpleBar from "simplebar-react";
import {Link} from "react-router-dom";

const SidebarContent = (props) => {
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{("Menu")}</li>
            <li>
              <Link to="/" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bx-collection"></i>
                <span key="t-dashboard">{("Dashboard")}</span>
              </Link>          
                <Link to="/customers" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bxs-business"></i>
                <span key="t-customers">{("Customers")}</span>
              </Link>
                <Link to="/persons" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bx-group"></i>
                <span key="t-persons">{("Persons")}</span>
              </Link>
                <Link to="/invoices" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bx-upload"></i>
                <span key="t-invoices">{("Invoices")}</span>
              </Link>
              <Link to="/conversations/inbox" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bx-conversation"></i>
                <span key="t-emails">{("Conversations")}</span>
              </Link>
                 <Link to="/expenses" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bx-chart"></i>
                <span key="t-expenses">{("Expenses")}</span>
              </Link>
                <Link to="/tenants" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bx-user-circle"></i>
                <span key="t-tenancies">{("Tenants")}</span>
              </Link>
               <Link to="/services" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bx-server"></i>
                <span key="t-users">{("Status page")}</span>
              </Link>
               <Link to="/users" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bx-group"></i>
                <span key="t-users">{("Users")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default SidebarContent;
