import PageMeta from "components/PageMeta";
import RevenueCard from "components/RevenueCard";
import UnreadEmailCard from "pages/Email/UnreadEmailCard";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import SalesAnalytics from "./SalesAnalytics";
import RecentCallsCard from "./RecentCallsCard";
import OrphanedPaymentsCard from "./OrphanedPaymentsCard";
import ServiceStatusBannerDashboard from "./ServiceStatusBannerDashboard";
import OutstandingCard from "../../components/OutstandingCard";

const Dashboard: React.FC = () => {
    return (
        <div className="page-content">
            <PageMeta title={`Dashboard`} />
            <Container fluid>
                <Row>
                    <Col xl={7}>

                        <SalesAnalytics />
                    </Col>
                    <Col xl={5}>

                        <Row>
                            <Col xl={4}>
                                <RevenueCard />
                            </Col>

                            <Col xl={4}>
                                <OutstandingCard />
                            </Col>

                            <Col xl={4}>
                                <ServiceStatusBannerDashboard />
                            </Col>

                        </Row>

                        <Row>
                            <Col xl={6}>
                                <OrphanedPaymentsCard />
                            </Col>

                            <Col xl={6}>
                                <RecentCallsCard />
                            </Col>

                        </Row>

                        <UnreadEmailCard />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Dashboard;
