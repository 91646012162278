import MoneyCard from "components/MoneyCard";
import {Invoice} from "models/Invoice";
import {AccountableContainer, FilterFinalInvoices} from "models/InvoiceContainer";
import React from "react";
import {connect} from "react-redux";
import {RootState} from "store/store";
import {InvoiceState} from 'store/reducers/Invoices';

interface Props {
    invoiceState: InvoiceState;
    tenantId?: string;
}

const OutstandingCard: React.FC<Props> = ({ invoiceState, tenantId }) => {

    let invoices = new AccountableContainer<Invoice>(invoiceState.getInvoices());

    if (tenantId !== undefined) {
        invoices = invoices.Filter(invoice => invoice.tenant === tenantId)
    }

    invoices = invoices.Filter(FilterFinalInvoices)
        .Filter(invoice => !invoice.IsPaid());

    const unpaid = invoices.CalcTotal(invoice => invoice.CalcPendingTotal())

    return (
        <MoneyCard
            title={"Unpaid"}
            amount={unpaid}
            subtitle={`${invoices.Count()} invoices`}
            timeSpan={"last week"}
            percentage={0}
            icon={`mdi mdi-clock text-primary`}
        />
    );

}

const mapStateToProps = (state: RootState) => {
    return {
        invoiceState: state.Invoices,
    };
};

export default connect(mapStateToProps)(OutstandingCard);
