import { Type } from "class-transformer";
import {InvoiceContent} from "./InvoiceContent";
import {InvoicePayment} from "./InvoicePayment";

export class Invoice {

  constructor() {
    this.payments = [];
    this.contents = [];
  }

  id!: string;
  name!: string;
  tenant!: string;
  quote!: boolean;
  @Type(() => Date)
  created!: Date;
  draft!: boolean;

  @Type(() => Date)
  deadline!: Date;
  customer!: string;
  total!: number;
  payments!: InvoicePayment[];
  contents!: InvoiceContent[];
  token!: string;

  public CalcTotalContents(): number {
    return this.total;
  }

  public CalcTaxes(amount?: number): number {
    if (amount === undefined) {
      amount = 21
    }
    return this.CalcTotalContents() / 100 * amount
  }

  public CalcGrandTotal(tax_amount?: number): number {
    return this.CalcTotalContents() + this.CalcTaxes(tax_amount)
  }


  public CalcPendingTotal(): number {
    const total = this.CalcTotalContents()
    let paymentTotal = 0;
    this.payments.forEach(payment => {
      paymentTotal += payment.amount
    })
    return total - paymentTotal;
  }

  public IsPaid(): boolean {
    let paymentTotal = 0;
    this.payments.forEach(payment => {
      paymentTotal += payment.amount
    })
    return (paymentTotal >= this.CalcGrandTotal()-0.01)
  }

  public GetCreatedDate(): Date {
    return new Date(this.created)
  }
  public GetDeadlineDate(): Date {
    return new Date(this.deadline)
  }
}

export class InvoicePaymentPortalInfoTenant {
  id!: string;
  name!: string;
  logo!: string;
}

export class InvoicePaymentPortalInfo {
  id!: string;
  name!: string;
  total!: number;
  paid!: boolean;
  tenant!: InvoicePaymentPortalInfoTenant;
}

export class InvoicePaymentIdealIssuer {
  id!: string;
  name!: string;
  image!: string;
}